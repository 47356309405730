<script src="../../../../../dist/cfg/settings.js"></script>
<template>
  <div>
    <div v-permission="'user.create'">
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }"
          >
            <template v-slot:body>
              <b-button-toolbar
                  aria-label="Toolbar with button groups and input groups"
              >
                <b-button-group size="sm" class="mr-1" >
                  <!--                  <b-button :to="{'name':'document-edit'}" class="mr-2 btn-primary">Nuovo documento</b-button>-->
                  <!--<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>-->
                  <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-if="$store.getters.currentUser.is_admin"
                      v-model="filter.organization_id"
                      :options="filteroptions.organizations"
                      id="inline-form-custom-select-pref"
                      required
                      @change="reloadList()"
                  >
                    <template v-slot:first>
                      <option :value="null">Filtra organizzazione</option>
                    </template>
                  </b-form-select>

                </b-button-group>
                <b-button-group v-if="!$store.getters.currentUser.is_admin || filter.organization_id" size="sm" class="mr-1">
                  <b-button :to="{'name':'setting-organization-user-edit','query':{'organization_id':filter.organization_id?filter.organization_id:''}}" class="mr-2 btn-primary">Nuovo utente instant call</b-button>
                  <!--<v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>-->
                </b-button-group>
              </b-button-toolbar>
            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
        </div>

      </div>
    </div>
    <div v-permission="'user.view'">
      <div class="row">
        <div class="col-md-12">
          <!--begin:: Widgets/Activity-->
          <KTPortlet
              v-bind="{
            }" v-bind:class="{ 'kt-spinner-f': loading }"
          >
            <template v-slot:body>

              <v-card>
                <v-card-title>
                  Elenco
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Cerca"
                      single-line
                      hide-details
                      v-on:input="deReloadList()"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    :single-select="singleSelect"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    multi-sortNO
                    item-key="id"
                    show-selectNO
                    class="elevation-1"
                    :search="search"
                    :footer-props="footerProps"
                    :server-items-length="pagination.totalNumberOfItems"
                    @update:page="paginate"
                >
                  <template v-slot:item.created_at="{ item }">
                    <span>{{item.created_at | formatDate}}</span>
                  </template>
                  <template v-slot:item.updated_at="{ item }">
                    <span>{{item.updated_at | formatDate}}</span>
                  </template>
                  <!--                                    <template v-slot:top>
                                                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                                                      </template>-->
                  <template v-slot:item.actions="{ item }">
                    <v-icon  v-permission="'user.edit'"
                             small
                             class="mr-2"
                             @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <div class="inlineblock"><click-confirm>
                      <v-icon  v-permission="'user.delete'"
                               small
                               @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </click-confirm></div>
                  </template>
                </v-data-table>
              </v-card>
            </template>
          </KTPortlet>
          <!--end:: Widgets/Activity-->
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import KTCodePreview from "@/views/partials/content/CodePreview.vue";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import UserService from "../../../../common/user.service";

export default {
  data() {
    return {
      loading:false,
      singleSelect: false,
      selected: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'name', value: 'name'},
        {text: 'iren code', value: 'iren_code'},
        {text: 'instant call code', value: 'instant_call_code'},
        {text: 'eva code', value: 'eva_code'},
        {text: 'type', value: 'type'},

      ],
      pagination: {
        //descending: !!this.$route.query.desc,
        //sortBy: this.$route.query.orderby || 'name',
        rowsPerPage: 100,
        //listSize: [10, 25, 50, 100],
        listSize: [100],
        page: 1,
        totalNumberOfItems: 0,
      },
      footerProps: {'items-per-page-options': [100]},
      items: [

      ],
      filteroptions: {
        organizations: []
      },
      filter: {
        organization_id: null
      },
    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Utenti", route: "/setting-organization/user/list"},
      {title: "Lista utenti", route: "list"}
    ]);

  },

  created () {
    this.initialize()
  },

  methods: {
    paginate(val) {
      this.pagination.page = val;
      this.reloadList();
    },
    deReloadList() {
      if (window.search_timeout) clearTimeout(window.search_timeout);
      var that = this;
      window.search_timeout = setTimeout(function () {
        that.reloadList();
      }, 800);
    },
    reloadList(){
      this.loading = true;
      let params = {
        page: this.pagination.page,
        // limit: this.pagination.rowsPerPage,
      };
      if(this.filter.organization_id){
        params.organization_id = this.filter.organization_id;
      }
      ApiService.get('organizations').then(({ data }) => {
        this.filteroptions.organizations = data.rows.map(x => {
          return {value: x.id, text: x.name}
        });
      })
      .catch(({ response }) => {
        //context.commit(SET_ERROR, response.data.errors);
      });
      ApiService.get('instantcall','?' + (new URLSearchParams(params)).toString())
          .then(({ data }) => {
            this.pagination.totalNumberOfItems = data.count;
            this.items = data.rows.filter((x) => {
              if (this.search && (!x.id || x.id != this.search) &&
                  (!x.name || x.name.toLowerCase().indexOf(this.search.toLowerCase()) === -1) &&
                  (!x.iren_code || x.iren_code.toLowerCase().indexOf(this.search.toLowerCase()) === -1) &&
                  (!x.instant_call_code || x.instant_call_code.toLowerCase().indexOf(this.search.toLowerCase()) === -1) &&
                  (!x.eva_code || x.eva_code.toLowerCase().indexOf(this.search.toLowerCase()) === -1)) {
                return false;
              }
              return true;
            });
            this.loading = false;
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
    initialize () {
      if(UserService.hasPermission(['user.edit','user.delete'])){
        this.headers.push({ text: 'Actions', value: 'actions', sortable: false });
      }
      this.reloadList();
    },


    editItem (item) {
      this.$router.push({ name: "setting-organization-user-edit", 'params':{'id':item.id} });
    },

    deleteItem (item) {
      ApiService.delete('instantcall/'+item.id,{'id':item.id}).then(() => this.reloadList());
    },


  },
};
</script>
